import { createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "modal",
  "data-bs-backdrop": "static",
  "data-bs-keyboard": "false",
  id: "addroutingmodal",
  ref: "addroutingmodalRef",
  tabindex: "-1"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-1000px" }
const _hoisted_3 = { class: "modal-content roundeda" }
const _hoisted_4 = {
  class: "modal-header bg-primary",
  id: "kt_modal_create_api_key_header"
}
const _hoisted_5 = {
  key: 0,
  class: "modal-title h5 text-uppercase text-white",
  id: "exampleModalLabel",
  style: {"margin-left":"1.1rem"}
}
const _hoisted_6 = {
  key: 1,
  class: "modal-title h5 text-uppercase text-white",
  id: "exampleModalLabel",
  style: {"margin-left":"1.1rem"}
}
const _hoisted_7 = { class: "modal-body py-10 px-lg-17" }
const _hoisted_8 = {
  class: "scroll-y me-n7 pe-7",
  id: "kt_modal_create_api_key_scroll",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-max-height": "auto",
  "data-kt-scroll-dependencies": "#kt_modal_create_api_key_header",
  "data-kt-scroll-wrappers": "#kt_modal_create_api_key_scroll",
  "data-kt-scroll-offset": "300px"
}
const _hoisted_9 = { class: "d-flex flex-column mb-10 fv-row" }
const _hoisted_10 = ["value", "title"]
const _hoisted_11 = { class: "fv-plugins-message-container" }
const _hoisted_12 = { class: "fv-help-block" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-md-6" }
const _hoisted_15 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_16 = { class: "fv-plugins-message-container" }
const _hoisted_17 = { class: "fv-help-block" }
const _hoisted_18 = { class: "col-md-6" }
const _hoisted_19 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_20 = { class: "fv-plugins-message-container" }
const _hoisted_21 = { class: "fv-help-block" }
const _hoisted_22 = { class: "col-md-6" }
const _hoisted_23 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_24 = { class: "fv-plugins-message-container" }
const _hoisted_25 = { class: "fv-help-block" }
const _hoisted_26 = { class: "col-md-6" }
const _hoisted_27 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_28 = { class: "fv-plugins-message-container" }
const _hoisted_29 = { class: "fv-help-block" }
const _hoisted_30 = { class: "col-md-6" }
const _hoisted_31 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_32 = { class: "fv-plugins-message-container" }
const _hoisted_33 = { class: "fv-help-block" }
const _hoisted_34 = { class: "col-md-6" }
const _hoisted_35 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_36 = { class: "fv-plugins-message-container" }
const _hoisted_37 = { class: "fv-help-block" }
const _hoisted_38 = { class: "modal-footer flex-center" }
const _hoisted_39 = {
  ref: "submitButtonRef",
  type: "submit",
  id: "kt_modal_create_api_key_submit",
  class: "btn btn-primary"
}
const _hoisted_40 = {
  key: 0,
  class: "indicator-label"
}
const _hoisted_41 = {
  key: 1,
  class: "indicator-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Modal - Create Api Key"),
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("begin::Modal dialog"),
      _createElementVNode("div", _hoisted_2, [
        _createCommentVNode("begin::Modal content"),
        _createElementVNode("div", _hoisted_3, [
          _createCommentVNode("begin::Modal header"),
          _createElementVNode("div", _hoisted_4, [
            _createCommentVNode("begin::Modal title"),
            (!_ctx.companyObject_for_edit?.id)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, " Enable Routing "))
              : _createCommentVNode("v-if", true),
            (_ctx.companyObject_for_edit?.id)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, " Update Enable Routing "))
              : _createCommentVNode("v-if", true),
            _createElementVNode("button", {
              type: "button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modalClose())),
              class: "btn btn-icon btn-sm",
              "data-bs-dismiss": "modal",
              "aria-label": "Close",
              id: "addnewnotificationmodal"
            }, _cache[8] || (_cache[8] = [
              _createElementVNode("i", {
                class: "bi bi-x-circle-fill light-text fs-3",
                style: {"color":"white"}
              }, null, -1 /* HOISTED */)
            ])),
            _createCommentVNode("end::Close")
          ]),
          _createCommentVNode("end::Modal header"),
          _createCommentVNode("begin::Form"),
          _createVNode(_component_Form, {
            id: "kt_modal_enable_routing_form",
            class: "form",
            onSubmit: _ctx.submit,
            "validation-schema": _ctx.validationSchema
          }, {
            default: _withCtx(() => [
              _createCommentVNode("begin::Modal body"),
              _createElementVNode("div", _hoisted_7, [
                _createCommentVNode("begin::Scroll"),
                _createElementVNode("div", _hoisted_8, [
                  _createCommentVNode("begin::Input group"),
                  _createElementVNode("div", _hoisted_9, [
                    _createCommentVNode("begin::Label"),
                    _cache[10] || (_cache[10] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Select Customer", -1 /* HOISTED */)),
                    _createCommentVNode("end::Label"),
                    _createCommentVNode("begin::Select"),
                    _createVNode(_component_Field, {
                      name: "se_account_id",
                      "data-control": "select2",
                      "data-hide-search": "true",
                      "data-placeholder": "Select Customer...",
                      class: "form-select form-select-solid border border-primary",
                      as: "select",
                      modelValue: _ctx.payload.se_account_id,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.payload.se_account_id) = $event))
                    }, {
                      default: _withCtx(() => [
                        _cache[9] || (_cache[9] = _createElementVNode("option", { value: "" }, "Select Customer...", -1 /* HOISTED */)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customer_list, (option, idx) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: idx,
                            value: option.id,
                            title: option.name
                          }, _toDisplayString(`${option.name}(${option.id})`), 9 /* TEXT, PROPS */, _hoisted_10))
                        }), 128 /* KEYED_FRAGMENT */))
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["modelValue"]),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createVNode(_component_ErrorMessage, { name: "se_account_id" })
                      ])
                    ]),
                    _createCommentVNode("end::Select")
                  ]),
                  _createCommentVNode("end::Input group"),
                  _createCommentVNode(" Row "),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_15, [
                        _createCommentVNode("begin::Label"),
                        _cache[11] || (_cache[11] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Maximum Route Time (sec)", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          type: "text",
                          class: "form-control form-control-solid border border-primary",
                          rows: "3",
                          name: "maximum_route_time",
                          placeholder: "Route Time",
                          modelValue: _ctx.payload.maximum_route_time,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payload.maximum_route_time) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createElementVNode("div", _hoisted_16, [
                          _createElementVNode("div", _hoisted_17, [
                            _createVNode(_component_ErrorMessage, { name: "maximum_route_time" })
                          ])
                        ]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_19, [
                        _createCommentVNode("begin::Label"),
                        _cache[12] || (_cache[12] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Maximum Slack Time (sec)", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          type: "text",
                          class: "form-control form-control-solid border border-primary",
                          rows: "3",
                          name: "maximum_slack_time",
                          placeholder: "Slack Time",
                          modelValue: _ctx.payload.maximum_slack_time,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.payload.maximum_slack_time) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createElementVNode("div", _hoisted_20, [
                          _createElementVNode("div", _hoisted_21, [
                            _createVNode(_component_ErrorMessage, { name: "maximum_slack_time" })
                          ])
                        ]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]),
                    _createElementVNode("div", _hoisted_22, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_23, [
                        _createCommentVNode("begin::Label"),
                        _cache[13] || (_cache[13] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Optimal Routing", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          type: "text",
                          class: "form-control form-control-solid border border-primary",
                          rows: "3",
                          name: "optimal_routing",
                          placeholder: "Optimal Routing",
                          modelValue: _ctx.payload.optimal_routing,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.payload.optimal_routing) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createElementVNode("div", _hoisted_24, [
                          _createElementVNode("div", _hoisted_25, [
                            _createVNode(_component_ErrorMessage, { name: "optimal_routing" })
                          ])
                        ]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]),
                    _createElementVNode("div", _hoisted_26, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_27, [
                        _createCommentVNode("begin::Label"),
                        _cache[15] || (_cache[15] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Enable/Disable Routing", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          name: "routing_enabled",
                          "data-control": "select2",
                          "data-hide-search": "true",
                          "data-placeholder": "enable/disable routing...",
                          class: "form-select form-select-solid border border-primary",
                          as: "select",
                          modelValue: _ctx.payload.routing_enabled,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.payload.routing_enabled) = $event))
                        }, {
                          default: _withCtx(() => _cache[14] || (_cache[14] = [
                            _createElementVNode("option", { value: "" }, "enable/disable routing...", -1 /* HOISTED */),
                            _createElementVNode("option", { value: "1" }, "Enable Routing", -1 /* HOISTED */),
                            _createElementVNode("option", { value: "0" }, "Disable Routing", -1 /* HOISTED */)
                          ])),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["modelValue"]),
                        _createElementVNode("div", _hoisted_28, [
                          _createElementVNode("div", _hoisted_29, [
                            _createVNode(_component_ErrorMessage, { name: "routing_enabled" })
                          ])
                        ]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]),
                    _createElementVNode("div", _hoisted_30, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_31, [
                        _createCommentVNode("begin::Label"),
                        _cache[17] || (_cache[17] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Enable/Disable Dynamic Routing", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          name: "is_dynamic_route",
                          "data-control": "select2",
                          "data-hide-search": "true",
                          "data-placeholder": "enable/disable routing...",
                          class: "form-select form-select-solid border border-primary",
                          as: "select",
                          modelValue: _ctx.payload.is_dynamic_route,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.payload.is_dynamic_route) = $event))
                        }, {
                          default: _withCtx(() => _cache[16] || (_cache[16] = [
                            _createElementVNode("option", { value: "" }, "enable/disable dynamic route...", -1 /* HOISTED */),
                            _createElementVNode("option", { value: "1" }, "Enable", -1 /* HOISTED */),
                            _createElementVNode("option", { value: "0" }, "Disable", -1 /* HOISTED */)
                          ])),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["modelValue"]),
                        _createElementVNode("div", _hoisted_32, [
                          _createElementVNode("div", _hoisted_33, [
                            _createVNode(_component_ErrorMessage, { name: "is_dynamic_route" })
                          ])
                        ]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]),
                    _createElementVNode("div", _hoisted_34, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_35, [
                        _createCommentVNode("begin::Label"),
                        _cache[19] || (_cache[19] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Calculate Initial Matrices", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          name: "is_matrices_calculation",
                          "data-control": "select2",
                          "data-hide-search": "true",
                          "data-placeholder": "enable/disable routing...",
                          class: "form-select form-select-solid border border-primary",
                          as: "select",
                          modelValue: _ctx.payload.is_matrices_calculation,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.payload.is_matrices_calculation) = $event))
                        }, {
                          default: _withCtx(() => _cache[18] || (_cache[18] = [
                            _createElementVNode("option", { value: "" }, "enable/disable route...", -1 /* HOISTED */),
                            _createElementVNode("option", { value: "1" }, "Enable", -1 /* HOISTED */),
                            _createElementVNode("option", { value: "0" }, "Disable", -1 /* HOISTED */)
                          ])),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["modelValue"]),
                        _createElementVNode("div", _hoisted_36, [
                          _createElementVNode("div", _hoisted_37, [
                            _createVNode(_component_ErrorMessage, { name: "is_dynamic_route" })
                          ])
                        ]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ])
                  ])
                ])
              ]),
              _createCommentVNode("begin::Modal footer"),
              _createElementVNode("div", _hoisted_38, [
                _createCommentVNode("begin::Button"),
                _cache[21] || (_cache[21] = _createElementVNode("button", {
                  type: "reset",
                  id: "kt_modal_create_api_key_cancel",
                  class: "btn btn-white me-3"
                }, " Clear ", -1 /* HOISTED */)),
                _createCommentVNode("end::Button"),
                _createCommentVNode("begin::Button"),
                _createElementVNode("button", _hoisted_39, [
                  (!_ctx.companyObject_for_edit?.id)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_40, " Submit "))
                    : _createCommentVNode("v-if", true),
                  (_ctx.companyObject_for_edit?.id)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_41, " Update "))
                    : _createCommentVNode("v-if", true),
                  _cache[20] || (_cache[20] = _createElementVNode("span", { class: "indicator-progress" }, [
                    _createTextVNode(" Please wait... "),
                    _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" })
                  ], -1 /* HOISTED */))
                ], 512 /* NEED_PATCH */),
                _createCommentVNode("end::Button")
              ]),
              _createCommentVNode("end::Modal footer")
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onSubmit", "validation-schema"])
        ])
      ])
    ], 512 /* NEED_PATCH */)
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}