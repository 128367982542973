import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "card",
  style: {"width":"100%","border":"0px"}
}
const _hoisted_2 = {
  class: "card-header border-0",
  style: {"margin":"-13px 0px"}
}
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-2" }
const _hoisted_6 = { class: "card-toolbar" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "card-body pt-0" }
const _hoisted_9 = {
  key: 0,
  title: "True",
  class: /*@__PURE__*/_normalizeClass(`badge badge-light-success`),
  style: {"margin-left":"1.3rem"}
}
const _hoisted_10 = {
  key: 1,
  class: /*@__PURE__*/_normalizeClass(`badge badge-light-success ms-3`),
  title: "False"
}
const _hoisted_11 = {
  key: 0,
  title: "True",
  class: /*@__PURE__*/_normalizeClass(`badge badge-light-success`),
  style: {"margin-left":"1.3rem"}
}
const _hoisted_12 = {
  key: 1,
  class: /*@__PURE__*/_normalizeClass(`badge badge-light-success ms-3`),
  title: "False"
}
const _hoisted_13 = {
  href: "#",
  class: "text-gray-600 text-hover-primary mx-1",
  title: "Edit"
}
const _hoisted_14 = {
  key: 0,
  class: /*@__PURE__*/_normalizeClass(`badge badge-light-success`)
}
const _hoisted_15 = ["onClick"]
const _hoisted_16 = {
  key: 0,
  class: "text-gray-600 text-hover-primary mb-1 cursor-pointer",
  title: "Delete"
}
const _hoisted_17 = {
  class: /*@__PURE__*/_normalizeClass(`badge badge-light-success`)
}
const _hoisted_18 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_RoutingConfig = _resolveComponent("RoutingConfig")!
  const _component_KTDatatable = _resolveComponent("KTDatatable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Card"),
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("begin::Card header"),
      _createElementVNode("div", _hoisted_2, [
        _createCommentVNode("begin::Card title"),
        _createElementVNode("div", _hoisted_3, [
          _createCommentVNode("begin::Search"),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen021.svg" })
            ]),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
              class: "form-control border mr-3 border-primary form-control-solid w-250px ps-14",
              onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
              placeholder: "search"
            }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
              [_vModelText, _ctx.search]
            ])
          ]),
          _createCommentVNode("end::Search")
        ]),
        _createCommentVNode("begin::Card title"),
        _createCommentVNode("begin::Card toolbar"),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.permissions.includes('enable-routing'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[2] || (_cache[2] = [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-primary mx-3",
                  "data-bs-toggle": "modal",
                  "data-bs-target": "#addroutingmodal"
                }, " Enable Routing ", -1 /* HOISTED */)
              ])))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", null, [
            _createVNode(_component_RoutingConfig, { companyObject: _ctx.companyObject }, null, 8 /* PROPS */, ["companyObject"])
          ]),
          _createCommentVNode("begin::Group actions"),
          _cache[3] || (_cache[3] = _createElementVNode("div", {
            class: "d-flex justify-content-end align-items-center d-none",
            "data-kt-subscription-table-toolbar": "selected"
          }, [
            _createElementVNode("div", { class: "fw-bolder me-5" }, [
              _createElementVNode("span", {
                class: "me-2",
                "data-kt-subscription-table-select": "selected_count"
              }),
              _createTextVNode(" Selected ")
            ]),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-danger",
              "data-kt-subscription-table-select": "delete_selected"
            }, " Delete Selected ")
          ], -1 /* HOISTED */)),
          _createCommentVNode("end::Group actions")
        ]),
        _createCommentVNode("end::Card toolbar")
      ]),
      _createCommentVNode("end::Card header"),
      _createCommentVNode("begin::Card body"),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_KTDatatable, {
          "table-data": _ctx.customers,
          total: _ctx.total,
          "table-header": _ctx.headerConfig,
          "enable-items-per-page-dropdown": true,
          loading: _ctx.loading,
          rowsPerPage: _ctx.pagination.page_size,
          onCurrentChange: _ctx.currentChange,
          onItemsPerPageChange: _ctx.itemsPerPageChange
        }, {
          "cell-id": _withCtx(({ row: customer }) => [
            _createElementVNode("div", null, _toDisplayString(customer.id), 1 /* TEXT */)
          ]),
          "cell-name": _withCtx(({ row: customer }) => [
            _createElementVNode("div", null, _toDisplayString(customer.name), 1 /* TEXT */)
          ]),
          "cell-optimal_routing": _withCtx(({ row: customer }) => [
            _createElementVNode("div", null, _toDisplayString(customer.optimal_routing), 1 /* TEXT */)
          ]),
          "cell-maximum_route_time": _withCtx(({ row: customer }) => [
            _createTextVNode(_toDisplayString(customer.maximum_route_time), 1 /* TEXT */)
          ]),
          "cell-maximum_slack_time": _withCtx(({ row: customer }) => [
            _createTextVNode(_toDisplayString(customer.maximum_slack_time), 1 /* TEXT */)
          ]),
          "cell-routing_enabled": _withCtx(({ row: customer }) => [
            (customer.routing_enabled == 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[4] || (_cache[4] = [
                  _createElementVNode("i", { class: "fas fa-check text-primary" }, null, -1 /* HOISTED */)
                ])))
              : (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[5] || (_cache[5] = [
                  _createElementVNode("i", { class: "bi bi-x-circle text-danger" }, null, -1 /* HOISTED */)
                ])))
          ]),
          "cell-is_dynamic_route": _withCtx(({ row: customer }) => [
            (customer.is_dynamic_route == 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[6] || (_cache[6] = [
                  _createElementVNode("i", { class: "fas fa-check text-primary" }, null, -1 /* HOISTED */)
                ])))
              : (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[7] || (_cache[7] = [
                  _createElementVNode("i", { class: "bi bi-x-circle text-danger" }, null, -1 /* HOISTED */)
                ])))
          ]),
          "cell-action": _withCtx(({ row: customer }) => [
            _createElementVNode("a", _hoisted_13, [
              (_ctx.permissions.includes('edit-routing'))
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createElementVNode("i", {
                      class: "fas fa-edit text-success",
                      "data-bs-toggle": "modal",
                      "data-bs-target": "#addroutingmodal",
                      onClick: ($event: any) => (_ctx.getCompanyDetail(customer))
                    }, null, 8 /* PROPS */, _hoisted_15)
                  ]))
                : _createCommentVNode("v-if", true)
            ]),
            (_ctx.permissions.includes('delete-routing'))
              ? (_openBlock(), _createElementBlock("a", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("i", {
                      class: "fas fa-trash text-danger",
                      onClick: ($event: any) => (_ctx.deltCompany(customer.id))
                    }, null, 8 /* PROPS */, _hoisted_18)
                  ])
                ]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["table-data", "total", "table-header", "loading", "rowsPerPage", "onCurrentChange", "onItemsPerPageChange"])
      ]),
      _createCommentVNode("end::Card body")
    ]),
    _createCommentVNode("end::Card")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}